import React, { cloneElement, ElementType, isValidElement } from 'react'
import { ButtonProps, scales, variants } from './types'
import styled from 'styled-components'

const StyledButton = styled.button<{variant?: string}>`
  width: 100%;
  height: 100%;
  border-radius: 12px;
  background: ${({variant}) => variant === 'outlined' 
          ? 'transparent'
          : '#232323'
};
  font-weight: 600;
  font-size: 16px;
  color: ${({variant}) => variant === 'outlined' ? '#232323' : '#FFF'};;
  border: ${({variant}) => variant === 'outlined' ? '2px solid #232323' : 'none'};
  cursor:  pointer;
  
  &:hover {
    opacity: 0.8;
  }
`

const Button = <E extends ElementType = 'button'>(props: ButtonProps<E>): JSX.Element => {

  const {children, variant} = props

  return (
   <StyledButton variant={variant}>
     {children}
   </StyledButton>
  )
}

Button.defaultProps = {
  isLoading: false,
  external: false,
  variant: variants.PRIMARY,
  scale: scales.MD,
  disabled: false,
}

export default Button
