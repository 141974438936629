import {Card, MenuContainer, StyledLink, MobileMenuHeader, MobileContainer, MobileBackground, MobileWrapper, StyledMobileLink} from "./style"
import {BurgerMenuIcon, CloseMenuIcon, MainLogoWithTextIcon} from "../../shared/assets/Svg";
import { Flex, Box, Button } from "../../shared/uikit";
import {useTranslation} from 'react-i18next';
import {PATHS} from "../../config/paths";
import {LangSelector} from "../../widgets/LangSelector";
import {useMatchBreakpoints} from "../../shared/lib/hooks";
import {useState} from "react";

const links = [
  {
    title: 'Products',
    path: PATHS.HOME
  },
  {
    title: 'Cases',
    path: PATHS.HOME
  },
  {
    title: 'Contract Us',
    path: PATHS.HOME
  },
  {
    title: 'Team',
    path: PATHS.HOME
  }
]

const Menu = () => {

  const {t} = useTranslation()
  const {isMobile, isTablet} = useMatchBreakpoints()
  const isSmall = isMobile || isTablet

  const [menuIsOpen, setMenuIsOpen] = useState(false)

  return (
    <>
      {
        !isSmall
          ? <MenuContainer>
            <MainLogoWithTextIcon/>
            <Flex>
              {
                links.map((link) => {
                  return (
                    <StyledLink to={link.path} key={link.path}>
                      {t(link.title)}
                    </StyledLink>
                  )
                })
              }
            </Flex>
            <Flex height='42px' width='90px'>
              <a href='https://app.tbccpay.com/login' style={{width: '100%', height:'100%'}}>
                <Button variant='outlined'>
                  {t('Login')}
                </Button>
              </a>
            </Flex>
          </MenuContainer>
          :<>
            <MobileMenuHeader>
              <MainLogoWithTextIcon width='150px'/>
              <Flex alignItems='center'>
                <Box onClick={() => setMenuIsOpen(!menuIsOpen)} ml='20px' pr='8px'>
                  {!menuIsOpen ? <BurgerMenuIcon /> : <CloseMenuIcon />}
                </Box>
              </Flex>
            </MobileMenuHeader>
            <MobileContainer isOpen={menuIsOpen}>
              <MobileBackground isOpen={menuIsOpen} />
              <MobileWrapper>
                {
                  links.map((link) => {
                    return (
                      <StyledMobileLink to={link.path} key={link.path}>
                        {t(link.title)}
                      </StyledMobileLink>
                    )
                  })
                }
                <Flex height='42px' width='calc(100vw - 62px)'>
                  <a href='https://app.tbccpay.com/login' style={{width: '100%', height:'100%'}}>
                    <Button variant='outlined'>
                      {t('Login')}
                    </Button>
                  </a>
                </Flex>
              </MobileWrapper>
            </MobileContainer>
          </>
      }
    </>
  )
}

export default Menu