import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = (props) => (
  <Svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M15.75 17.5L22.0081 22.1936C22.4266 22.5074 23.0162 22.4457 23.3607 22.0521L35 8.75" stroke="white" strokeWidth="2" strokeLinecap="round"/>
    <path d="M36.75 21C36.75 24.2909 35.7192 27.4991 33.8023 30.174C31.8855 32.849 29.1789 34.8563 26.0627 35.9141C22.9465 36.972 19.5772 37.0271 16.428 36.0718C13.2789 35.1165 10.508 33.1988 8.50469 30.588C6.50134 27.9772 5.36611 24.8044 5.25843 21.5153C5.15076 18.2262 6.07605 14.986 7.90435 12.2498C9.73266 9.51352 12.3721 7.41869 15.4521 6.25948C18.532 5.10028 21.8977 4.93493 25.0764 5.78667" stroke="white" strokeWidth="2" strokeLinecap="round"/>
  </Svg>
)

export default Icon
