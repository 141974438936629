import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = (props) => (
  <Svg width="25" height="29" viewBox="0 0 25 29" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path fillRule="evenodd" clipRule="evenodd" d="M24.1179 15.7964L24.9894 6.20898C25.0843 5.1764 24.5363 4.19269 23.609 3.72972L19.4142 1.633C15.0612 -0.544333 9.93889 -0.544333 5.58706 1.633L1.39079 3.72972C0.464651 4.19268 -0.0833176 5.1764 0.0103628 6.20898L0.881899 15.7964C1.21413 19.4447 3.11969 22.767 6.10064 24.8964C7.9544 26.2207 9.94616 27.6431 11.0375 28.4227C11.9133 29.0481 13.0882 29.0481 13.964 28.4227C15.0556 27.6431 17.0473 26.221 18.9008 24.8964C21.882 22.7664 23.7873 19.4446 24.1181 15.7964H24.1179ZM22.8424 6.01299L21.9708 15.6004C21.6947 18.6233 20.1171 21.3758 17.6465 23.1405L12.7097 26.6682C12.5845 26.7573 12.4162 26.7573 12.2912 26.6682L7.35299 23.1405C4.8839 21.376 3.30476 18.6236 3.03007 15.6004L2.15854 6.01299C2.14553 5.86638 2.2233 5.72529 2.35547 5.65933L6.55174 3.5612C10.2966 1.68888 14.7044 1.68888 18.4492 3.5612L22.6439 5.65933C22.7764 5.72553 22.8553 5.86638 22.8423 6.01299H22.8424Z" fill="white"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M15.7765 11.1683L19.3716 9.73028C19.9239 9.50879 20.1928 8.88044 19.9727 8.32815C19.7512 7.77443 19.1229 7.50549 18.5706 7.727L14.9755 9.165C14.4218 9.38649 14.1528 10.0134 14.3743 10.5671C14.5958 11.1194 15.2242 11.3883 15.7765 11.1683Z" fill="white"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M10.0244 9.16523L6.42929 7.72724C5.87701 7.50575 5.24867 7.77467 5.02717 8.32839C4.80567 8.88067 5.07459 9.50901 5.62832 9.73052L9.22343 11.1685C9.7757 11.3886 10.404 11.1196 10.6256 10.5674C10.8456 10.0136 10.5767 9.38674 10.0244 9.16523Z" fill="white"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M15.9276 18.1563C13.8078 16.9268 11.1921 16.9268 9.07232 18.1563L7.51057 16.5945C7.08926 16.1732 6.406 16.1732 5.98468 16.5945C5.56337 17.0159 5.56337 17.6991 5.98468 18.1204L8.1418 20.2775C8.34451 20.4788 8.61776 20.5924 8.904 20.5924C8.904 20.5924 9.1356 20.6327 9.46639 20.4343L10.0947 20.0575C11.5746 19.1701 13.4238 19.1701 14.9037 20.0575L15.532 20.4343L15.5494 20.4444L15.5624 20.4516L15.5768 20.4588C15.883 20.63 16.0944 20.5927 16.0944 20.5927C16.3807 20.5927 16.6554 20.4791 16.8581 20.2778L19.0152 18.1207C19.4351 17.6994 19.4351 17.0161 19.0152 16.5948C18.5939 16.1735 17.9106 16.1735 17.4893 16.5948L15.9276 18.1565L15.9276 18.1563Z" fill="white"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M11.4211 18.7949V27.0942C11.4211 27.6896 11.9043 28.1728 12.4997 28.1728C13.0951 28.1728 13.5783 27.6896 13.5783 27.0942V18.7949C13.5783 18.1995 13.0951 17.7163 12.4997 17.7163C11.9043 17.7163 11.4211 18.1995 11.4211 18.7949Z" fill="white"/>
  </Svg>
)

export default Icon
