import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = (props) => (
  <Svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M9.04778 11.0344L9.4417 11.9536L9.04778 11.0344C8.22855 11.3855 7.74051 12.2364 7.85106 13.1208L9.21405 24.0247C9.4342 25.7859 10.3152 27.3977 11.6787 28.534L19.7196 35.2347C20.4613 35.8528 21.5387 35.8528 22.2804 35.2347L30.3213 28.534C31.6848 27.3977 32.5658 25.7859 32.7859 24.0247L34.1489 13.1208C34.2595 12.2364 33.7715 11.3855 32.9522 11.0344L21.7878 6.24968C21.2847 6.03406 20.7153 6.03406 20.2122 6.24968L9.04778 11.0344Z" stroke="white" strokeWidth="2" strokeLinecap="round"/>
    <path d="M15.75 21L20.5687 25.8187C20.7918 26.0418 21.1633 26.0051 21.3383 25.7425L28 15.75" stroke="white" strokeWidth="2" strokeLinecap="round"/>
  </Svg>
)

export default Icon
