import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = (props) => (
  <Svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M33.7499 10.8L30.8832 12.95C30.537 13.2096 30.116 13.35 29.6832 13.35H23.086C22.3284 13.35 21.6359 13.778 21.2971 14.4556L20.8649 15.32C20.4468 16.1562 20.6637 17.1704 21.3873 17.7624L26.9759 22.3349C28.5498 23.6227 29.3643 25.62 29.1398 27.6412L28.7435 31.2077C28.6813 31.7677 28.5601 32.3195 28.3819 32.854L27.7999 34.6" stroke="white" strokeWidth="2"/>
    <path d="M4.8501 18.45L12.2882 17.2103C13.6418 16.9847 14.8154 18.1583 14.5898 19.5119L13.8358 24.0359C13.5471 25.768 14.4219 27.4859 15.9925 28.2712L17.9976 29.2738C19.2553 29.9026 19.9075 31.3205 19.5664 32.6847L18.4501 37.15" stroke="white" strokeWidth="2"/>
    <circle cx="21" cy="21" r="16" stroke="white" strokeWidth="2"/>
  </Svg>
)

export default Icon
