import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = (props) => (
  <Svg width="14" height="10" viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M14 5L6.5 0.669873L6.5 9.33013L14 5ZM-6.55671e-08 5.75L7.25 5.75L7.25 4.25L6.55671e-08 4.25L-6.55671e-08 5.75Z" fill="black" fillOpacity="0.15"/>
  </Svg>
)

export default Icon
