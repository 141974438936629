import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = (props) => (
  <Svg width="29" height="32" viewBox="0 0 29 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path fillRule="evenodd" clipRule="evenodd" d="M4.18244 20.7346C2.91074 18.8381 2.17017 16.5567 2.17017 14.1045C2.17017 7.51787 7.51823 2.16993 14.1048 2.16993C20.6913 2.16993 26.0394 7.51799 26.0394 14.1045C26.0394 20.6912 20.6913 26.0391 14.1048 26.0391C13.5059 26.0391 13.0198 26.5252 13.0198 27.1241C13.0198 27.723 13.5059 28.209 14.1048 28.209C21.8892 28.209 28.2093 21.889 28.2093 14.1045C28.2093 6.32007 21.8892 0 14.1048 0C6.32031 0 0.000244141 6.32007 0.000244141 14.1045C0.000244141 17.0037 0.876957 19.7001 2.37842 21.9411C2.71263 22.4387 3.38663 22.5717 3.88429 22.2392C4.38197 21.9064 4.51493 21.231 4.18241 20.7347L4.18244 20.7346Z" fill="white"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M15.8102 16.4294C15.8102 16.7578 15.6785 17.0731 15.4472 17.3061C15.2142 17.5391 14.8989 17.6691 14.569 17.6691H12.658C12.3643 17.6691 12.1676 17.3611 12.1676 17.3611C11.7973 16.8895 11.1144 16.8084 10.6443 17.1787C10.1728 17.549 10.0917 18.2319 10.462 18.702C10.462 18.702 11.4023 19.839 12.658 19.839H14.5691C15.4745 19.839 16.3411 19.4804 16.9807 18.8407C17.62 18.2014 17.9804 17.3335 17.9804 16.4292C17.9804 15.5251 17.6203 14.6571 16.9807 14.0176C16.3413 13.3782 15.4748 13.0193 14.5691 13.0193H13.6403C13.3105 13.0193 12.9952 12.889 12.7622 12.6562C12.5307 12.4233 12.3992 12.1079 12.3992 11.7795C12.3992 11.4511 12.5309 11.1358 12.7622 10.9028C12.9952 10.6698 13.3105 10.5398 13.6403 10.5398H15.5514C15.8451 10.5398 16.0418 10.8478 16.0418 10.8478C16.4121 11.3194 17.095 11.4005 17.5651 11.0302C18.0366 10.6599 18.1177 9.97698 17.7474 9.50691C17.7474 9.50691 16.807 8.36987 15.5513 8.36987H13.6403C12.7348 8.36987 11.8683 8.72854 11.2287 9.36816C10.5894 10.0075 10.229 10.8755 10.229 11.7798C10.229 12.6838 10.5891 13.5518 11.2287 14.1914C11.868 14.8307 12.7346 15.1896 13.6403 15.1896H14.569C14.8989 15.1896 15.2142 15.3199 15.4472 15.5527C15.6787 15.7856 15.8102 16.101 15.8102 16.4294Z" fill="white"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M15.1895 9.04158V7.90454C15.1895 7.30563 14.7034 6.81958 14.1045 6.81958C13.5056 6.81958 13.0195 7.30563 13.0195 7.90454V9.04158C13.0195 9.6405 13.5056 10.1265 14.1045 10.1265C14.7034 10.1265 15.1895 9.6405 15.1895 9.04158Z" fill="white"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M15.1895 20.305V19.168C15.1895 18.5691 14.7034 18.083 14.1045 18.083C13.5056 18.083 13.0195 18.5691 13.0195 19.168V20.305C13.0195 20.9039 13.5056 21.39 14.1045 21.39C14.7034 21.39 15.1895 20.9039 15.1895 20.305Z" fill="white"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M8.31826 26.0398H1.08496C0.486051 26.0398 0 26.5258 0 27.1248C0 27.7237 0.486051 28.2097 1.08496 28.2097H8.31826C8.91717 28.2097 9.40322 27.7237 9.40322 27.1248C9.40322 26.5258 8.91717 26.0398 8.31826 26.0398Z" fill="white"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M7.63818 30.7844L10.5315 27.8911C10.9553 27.4673 10.9553 26.7815 10.5315 26.3577L7.63818 23.4643C7.21582 23.0405 6.52705 23.0405 6.10472 23.4643C5.68091 23.8867 5.68091 24.5755 6.10472 24.9978L8.22983 27.1244L6.10472 29.251C5.68091 29.6733 5.68091 30.3621 6.10472 30.7844C6.52708 31.2082 7.21585 31.2082 7.63818 30.7844Z" fill="white"/>
  </Svg>
)

export default Icon
