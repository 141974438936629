import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = (props) => (
  <Svg width="161" height="35" viewBox="0 0 161 35" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0_246_469)">
          <path fillRule="evenodd" clipRule="evenodd" d="M9.23447 0.00292969H33.6453C34.2277 0.00292969 34.7625 0.311774 35.0596 0.822556L42.1784 13.2001C42.5469 13.8415 42.4399 14.6493 41.917 15.172L22.3431 34.5341C21.7132 35.1637 20.6912 35.1637 20.0494 34.5341L0.499324 15.1957C-0.0354804 14.6612 -0.142441 13.8297 0.261633 13.1882L7.86775 0.78692C8.16486 0.299896 8.68778 0.0148083 9.25824 0.0148083L9.23447 0.00292969ZM30.4959 5.5265V8.99506H23.5435V11.4064C28.428 11.6678 32.0885 12.725 32.1241 13.9841V16.6211C32.1003 17.8922 28.428 18.9494 23.5435 19.1988V25.1025H18.9204V19.1988C14.0358 18.9375 10.3754 17.8803 10.3397 16.6211V13.9841C10.3635 12.7131 14.0358 11.6559 18.9204 11.4064V8.99506H11.9679V5.5265H30.4959ZM21.226 17.3933C26.4433 17.3933 30.793 16.5024 31.8627 15.3026C30.9594 14.2929 27.6912 13.4971 23.5316 13.2714V15.7896C22.7829 15.8253 22.0104 15.849 21.226 15.849C20.4297 15.849 19.6572 15.8253 18.9204 15.7896V13.2714C14.7727 13.4852 11.4925 14.2811 10.5893 15.3026C11.6589 16.4905 16.0087 17.3933 21.226 17.3933Z" fill="#009393"/>
          <path d="M51.7097 6.71453L57.3549 6.08496V11.561H62.2513V16.3243H57.3073V23.7129C57.3073 25.459 58.0442 26.338 59.506 26.338C60.4448 26.338 61.2292 26.1836 61.8591 25.8629L63.1545 30.6262C61.871 31.1251 60.5162 31.4221 59.0781 31.5052C56.8082 31.6122 55.0017 31.0657 53.6707 29.8779C52.3634 28.6544 51.7097 26.6112 51.7097 23.7247V16.3362H48.2632V11.5729H51.7097V6.72641V6.71453Z" fill="#009393"/>
          <path d="M83.3705 23.119H69.1447C69.2992 24.1287 69.834 24.9602 70.7491 25.6254C71.6642 26.2668 72.8764 26.5757 74.3977 26.5757C76.5606 26.5757 78.2364 26.0649 79.4129 25.0314L82.5861 28.6069C80.6489 30.5431 77.8798 31.5053 74.2788 31.5053C70.8323 31.5053 68.1464 30.5669 66.2449 28.69C64.3671 26.8132 63.4282 24.2712 63.4282 21.064C63.4282 18.0468 64.3552 15.6117 66.2092 13.7586C68.0632 11.8818 70.559 10.9434 73.6965 10.9434C77.1192 10.9434 79.7338 12.0006 81.5403 14.115C83.3705 16.2294 83.9885 19.2347 83.3824 23.1308L83.3705 23.119ZM69.1447 18.9496H78.3196C78.272 17.9399 77.7966 17.1796 76.9053 16.6451C76.014 16.0868 74.9444 15.8136 73.6965 15.8136C72.5674 15.8136 71.6048 16.0868 70.7966 16.6451C69.9885 17.2034 69.4418 17.9636 69.1447 18.9496Z" fill="#009393"/>
          <path d="M88.0764 6.71453L93.7216 6.08496V11.561H98.618V16.3243H93.674V23.7129C93.674 25.459 94.4109 26.338 95.8727 26.338C96.8115 26.338 97.5959 26.1836 98.2258 25.8629L99.5212 30.6262C98.2377 31.1251 96.8829 31.4221 95.4448 31.5052C93.1749 31.6122 91.3684 31.0657 90.0374 29.8779C88.7301 28.6544 88.0764 26.6112 88.0764 23.7247V16.3362H84.6299V11.5729H88.0764V6.72641V6.71453Z" fill="#009393"/>
          <path d="M101.637 3.26953H107.318V13.8772C108.708 12.0478 110.657 11.1332 113.2 11.1332C118.346 11.1332 120.925 14.2692 120.925 20.5411V31.0655H115.28V20.5886C115.28 19.2344 114.936 18.1891 114.258 17.4526C113.604 16.7162 112.713 16.336 111.596 16.336C110.372 16.336 109.338 16.7637 108.494 17.607C107.686 18.4504 107.282 19.6264 107.282 21.135V31.0655H101.637V3.26953Z" fill="#009393"/>
          <path d="M143.055 23.119H128.829C128.983 24.1287 129.518 24.9602 130.433 25.6254C131.348 26.2668 132.561 26.5757 134.082 26.5757C136.245 26.5757 137.92 26.0649 139.097 25.0314L142.27 28.6069C140.333 30.5431 137.564 31.5053 133.963 31.5053C130.516 31.5053 127.83 30.5669 125.929 28.69C124.051 26.8132 123.112 24.2712 123.112 21.064C123.112 18.0468 124.039 15.6117 125.893 13.7586C127.747 11.8818 130.243 10.9434 133.381 10.9434C136.803 10.9434 139.418 12.0006 141.224 14.115C143.055 16.2294 143.673 19.2347 143.066 23.1308L143.055 23.119ZM128.829 18.9496H138.004C137.956 17.9399 137.481 17.1796 136.589 16.6451C135.698 16.0868 134.628 15.8136 133.381 15.8136C132.252 15.8136 131.289 16.0868 130.481 16.6451C129.673 17.2034 129.126 17.9636 128.829 18.9496Z" fill="#009393"/>
          <path d="M145.396 11.4898H150.649L151.076 13.6398C152.301 11.8937 154.024 11.0146 156.246 11.0146C158.076 11.0146 159.657 11.6086 160.988 12.7964L158.635 17.3222C157.72 16.657 156.686 16.3244 155.533 16.3244C154.25 16.3244 153.18 16.7402 152.324 17.5598C151.457 18.3556 151.029 19.4485 151.029 20.8502V31.0539H145.384V11.4779L145.396 11.4898Z" fill="#009393"/>
      </g>
      <defs>
          <clipPath id="clip0_246_469">
              <rect width="161" height="35" fill="white"/>
          </clipPath>
      </defs>
  </Svg>
)

export default Icon
