import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = (props) => (
  <Svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M11.6719 25.9601C12.3011 27.76 13.3524 29.3899 14.7447 30.7095C16.4279 32.3049 18.5375 33.3784 20.8181 33.8C23.0987 34.2217 25.4526 33.9734 27.595 33.0852C29.7375 32.1971 31.5767 30.7071 32.89 28.7956C34.2034 26.8841 34.9347 24.6329 34.9955 22.3145C35.0563 19.996 34.4439 17.7096 33.2325 15.7319C32.021 13.7542 30.2625 12.1699 28.1695 11.1708C26.0765 10.1716 23.7388 9.80032 21.4393 10.1019L21.6993 12.0849C23.6156 11.8336 25.5637 12.143 27.3078 12.9756C29.052 13.8083 30.5175 15.1285 31.527 16.7766C32.5365 18.4247 33.0468 20.33 32.9962 22.262C32.9456 24.1941 32.3361 26.0701 31.2416 27.663C30.1472 29.2559 28.6145 30.4976 26.8291 31.2377C25.0438 31.9778 23.0822 32.1847 21.1817 31.8334C19.2812 31.482 17.5232 30.5875 16.1205 29.2579C15.0269 28.2214 14.1857 26.9551 13.6536 25.557L12.9537 25.7446L12.9082 25.7569C12.7311 25.8049 12.382 25.8996 12.0475 25.938C11.9373 25.9507 11.8108 25.9599 11.6719 25.9601Z" fill="white"/>
      <path d="M13.2598 9.30635C14.662 8.23045 16.2308 7.39571 17.9017 6.83401C18.3348 6.68842 18.5513 6.61563 18.7419 6.71788C18.9326 6.82013 18.9943 7.05046 19.1177 7.51111L22.7412 21.0341C22.8632 21.4894 22.9242 21.7171 22.8206 21.8964C22.7171 22.0758 22.4894 22.1368 22.0341 22.2588L8.51111 25.8823C8.05046 26.0057 7.82014 26.0674 7.63627 25.9535C7.4524 25.8395 7.40717 25.6156 7.31671 25.1677C6.96771 23.4398 6.9062 21.6638 7.13688 19.9116C7.41114 17.8284 8.09302 15.8196 9.14359 14C10.1942 12.1804 11.5929 10.5854 13.2598 9.30635Z" stroke="white" strokeWidth="2"/>
  </Svg>
)

export default Icon
