import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = (props) => (
  <Svg width="34" height="32" viewBox="0 0 34 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M13.7516 6.5983C15.1282 3.16656 15.8165 1.45068 17 1.45068C18.1836 1.45068 18.8719 3.16656 20.2484 6.59831L20.3125 6.75811C21.0902 8.69688 21.4791 9.66626 22.2716 10.2555C23.0641 10.8447 24.1044 10.9378 26.185 11.1242L26.5611 11.1579C29.9663 11.4628 31.6689 11.6153 32.0332 12.6985C32.3975 13.7817 31.1331 14.9321 28.6042 17.2328L27.7603 18.0007C26.4801 19.1653 25.84 19.7476 25.5417 20.5109C25.4861 20.6532 25.4398 20.7991 25.4032 20.9475C25.2071 21.7432 25.3946 22.588 25.7694 24.2775L25.8861 24.8034C26.575 27.9085 26.9195 29.4611 26.3181 30.1307C26.0933 30.381 25.8012 30.5611 25.4768 30.6497C24.6084 30.8866 23.3756 29.882 20.9099 27.8729C19.2908 26.5536 18.4813 25.8939 17.5519 25.7455C17.1863 25.6872 16.8138 25.6872 16.4482 25.7455C15.5188 25.8939 14.7092 26.5536 13.0902 27.8729C10.6245 29.882 9.39168 30.8866 8.52333 30.6497C8.19885 30.5611 7.90676 30.381 7.68202 30.1307C7.08058 29.4611 7.42504 27.9085 8.11397 24.8034L8.23065 24.2775C8.60552 22.588 8.79295 21.7432 8.59686 20.9475C8.56028 20.7991 8.51401 20.6532 8.45836 20.5109C8.16003 19.7476 7.51997 19.1653 6.23983 18.0007L5.39584 17.2328C2.86701 14.9321 1.6026 13.7817 1.96691 12.6985C2.33122 11.6153 4.03381 11.4628 7.43898 11.1579L7.81512 11.1242C9.89573 10.9378 10.936 10.8447 11.7285 10.2555C12.521 9.66626 12.9098 8.69688 13.6875 6.75811L13.7516 6.5983Z" stroke="white" strokeWidth="2"/>
  </Svg>
)

export default Icon
