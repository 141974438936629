import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = (props) => (
  <Svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <ellipse cx="20.5" cy="21" rx="1.5" ry="2" fill="white"/>
    <path d="M27 21C27 25.5967 26.2309 29.7115 25.0251 32.6401C24.4212 34.1067 23.7274 35.2277 23.0082 35.9665C22.292 36.7021 21.6138 37 21 37C20.3862 37 19.708 36.7021 18.9918 35.9665C18.2726 35.2277 17.5788 34.1067 16.9749 32.6401C15.7691 29.7115 15 25.5967 15 21C15 16.4033 15.7691 12.2885 16.9749 9.35993C17.5788 7.89331 18.2726 6.77228 18.9918 6.0335C19.708 5.29788 20.3862 5 21 5C21.6138 5 22.292 5.29788 23.0082 6.0335C23.7274 6.77228 24.4212 7.89331 25.0251 9.35993C26.2309 12.2885 27 16.4033 27 21Z" stroke="white" strokeWidth="2"/>
    <path d="M23.877 25.8684C19.9209 28.1524 16.008 29.5542 12.9053 30.0013C11.3509 30.2252 10.0524 30.2019 9.0724 29.9684C8.0952 29.7355 7.5275 29.3206 7.24302 28.8278C6.95853 28.3351 6.88301 27.6359 7.16997 26.6733C7.45777 25.7078 8.08679 24.5716 9.05791 23.3374C10.9964 20.8739 14.1669 18.1861 18.1229 15.9021C22.079 13.6181 25.9918 12.2163 29.0946 11.7693C30.6489 11.5453 31.9474 11.5687 32.9275 11.8022C33.9047 12.035 34.4724 12.45 34.7568 12.9427C35.0413 13.4354 35.1169 14.1346 34.8299 15.0973C34.5421 16.0627 33.9131 17.1989 32.942 18.4331C31.0034 20.8966 27.833 23.5844 23.877 25.8684Z" stroke="white" strokeWidth="2"/>
    <path d="M23.877 15.9018C19.9209 13.6178 16.008 12.216 12.9053 11.769C11.3509 11.545 10.0524 11.5684 9.0724 11.8019C8.0952 12.0347 7.5275 12.4497 7.24302 12.9424C6.95853 13.4352 6.88301 14.1343 7.16997 15.097C7.45777 16.0625 8.08679 17.1987 9.05791 18.4328C10.9964 20.8964 14.1669 23.5841 18.1229 25.8681C22.079 28.1522 25.9918 29.5539 29.0946 30.001C30.6489 30.2249 31.9474 30.2016 32.9275 29.9681C33.9047 29.7352 34.4724 29.3203 34.7568 28.8275C35.0413 28.3348 35.1169 27.6357 34.8299 26.673C34.5421 25.7075 33.9131 24.5713 32.942 23.3372C31.0034 20.8736 27.833 18.1858 23.877 15.9018Z" stroke="white" strokeWidth="2"/>
  </Svg>
)

export default Icon
