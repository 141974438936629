import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = (props) => (
  <Svg width="189" height="37" viewBox="0 0 189 37" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M11.5595 15.5477L18.9004 8.36256L26.2452 15.5515L30.5167 11.3705L18.9004 0L7.28798 11.3667L11.5595 15.5477Z" fill="#F3BA2F"/>
      <path d="M8.54301 18.4994L4.27161 14.3185L0 18.4996L4.2714 22.6806L8.54301 18.4994Z" fill="#F3BA2F"/>
      <path d="M11.5595 21.4523L18.9004 28.6374L26.2449 21.4488L30.5188 25.6274L30.5167 25.6298L18.9004 37L7.28171 25.6277L11.5595 21.4523Z" fill="#F3BA2F"/>
      <path d="M33.5296 22.6824L37.8012 18.5012L33.5298 14.3203L29.2582 18.5014L33.5296 22.6824Z" fill="#F3BA2F"/>
      <path d="M23.2331 18.4978H23.2349L18.9004 14.2552L14.5638 18.4999L14.5698 18.506L18.9004 22.7448L23.2349 18.5022L23.237 18.4999L23.2331 18.4978Z" fill="#F3BA2F"/>
      <path d="M44.4603 8.96544H53.7505C56.0562 8.96544 57.7998 9.54464 58.9815 10.7031C59.896 11.6002 60.3531 12.7122 60.3531 14.0386V14.0947C60.3531 14.6552 60.2823 15.1506 60.1399 15.5801C59.9978 16.0103 59.8079 16.3975 59.5706 16.7432C59.3338 17.0892 59.0585 17.3929 58.7457 17.6541C58.4325 17.916 58.096 18.1404 57.7356 18.3269C58.8946 18.757 59.807 19.3406 60.4722 20.0788C61.1371 20.8173 61.47 21.8404 61.47 23.1481V23.2039C61.47 24.1011 61.2939 24.8857 60.9413 25.5588C60.5887 26.2312 60.0835 26.792 59.4258 27.2406C58.768 27.6891 57.9772 28.0252 57.0528 28.2496C56.1285 28.4738 55.1041 28.5857 53.9801 28.5857H44.4603V8.96544ZM52.8217 16.8975C53.7956 16.8975 54.5688 16.7345 55.1412 16.4072C55.7138 16.0802 56.0001 15.5521 56.0001 14.8236V14.7674C56.0001 14.1137 55.7517 13.614 55.2555 13.268C54.759 12.9223 54.0431 12.7493 53.108 12.7493H48.7553V16.8975H52.8217ZM53.9959 24.8022C54.9695 24.8022 55.7326 24.6297 56.2867 24.2837C56.8403 23.938 57.1173 23.4006 57.1173 22.6718V22.616C57.1173 21.9619 56.8594 21.4435 56.3441 21.0601C55.8284 20.6776 54.9982 20.4859 53.8526 20.4859H48.7553V24.8025L53.9959 24.8022Z" fill="#F3BA2F"/>
      <path d="M67.0029 8.96544H71.4132V28.5863H67.0029V8.96544Z" fill="#F3BA2F"/>
      <path d="M78.0928 8.96544H82.1586L91.5515 21.0464V8.96544H95.9042V28.5863H92.1531L82.4452 16.1132V28.5863H78.0928V8.96544Z" fill="#F3BA2F"/>
      <path d="M109.256 8.82546H113.322L121.913 28.5863H117.302L115.47 24.1858H106.993L105.161 28.5863H100.664L109.256 8.82546ZM113.895 20.3734L111.231 14.0109L108.569 20.3734H113.895Z" fill="#F3BA2F"/>
      <path d="M126.674 8.96544H130.74L140.133 21.0464V8.96544H144.485V28.5863H140.734L131.026 16.1132V28.5863H126.674V8.96544Z" fill="#F3BA2F"/>
      <path d="M160.357 28.9224C158.886 28.9224 157.522 28.6605 156.261 28.1377C155.001 27.6149 153.913 26.8998 152.997 25.9933C152.08 25.0871 151.364 24.0172 150.849 22.784C150.334 21.5505 150.076 20.2331 150.076 18.8315V18.7757C150.076 17.3742 150.334 16.0617 150.849 14.8376C151.364 13.6137 152.08 12.5392 152.997 11.6142C153.913 10.6893 155.011 9.9602 156.29 9.42775C157.568 8.8953 158.982 8.62908 160.528 8.62908C161.463 8.62908 162.317 8.70418 163.091 8.85322C163.864 9.00314 164.565 9.20828 165.196 9.46983C165.826 9.73167 166.408 10.0493 166.943 10.4228C167.476 10.7969 167.973 11.2077 168.431 11.6563L165.626 14.8236C164.842 14.1324 164.045 13.59 163.234 13.1979C162.422 12.8057 161.511 12.6093 160.499 12.6093C159.659 12.6093 158.881 12.7683 158.166 13.0859C157.45 13.4036 156.834 13.8425 156.318 14.403C155.803 14.9635 155.402 15.6132 155.115 16.3511C154.83 17.0895 154.687 17.8789 154.687 18.7196V18.7754C154.687 19.6162 154.83 20.4108 155.115 21.1577C155.402 21.9055 155.798 22.5593 156.304 23.1198C156.81 23.6803 157.421 24.1245 158.137 24.4515C158.853 24.7788 159.64 24.9419 160.499 24.9419C161.645 24.9419 162.613 24.7364 163.405 24.3252C164.198 23.9147 164.986 23.3539 165.768 22.6434L168.575 25.4185C168.059 25.9606 167.524 26.4466 166.971 26.8759C166.417 27.306 165.811 27.6748 165.152 27.9834C164.494 28.2914 163.773 28.5255 162.991 28.6839C162.207 28.8426 161.33 28.9224 160.357 28.9224Z" fill="#F3BA2F"/>
      <path d="M173.765 8.96544H188.856V12.8057H178.118V16.7859H187.568V20.6259H178.118V24.7463H189V28.5863H173.765V8.96544Z" fill="#F3BA2F"/>
  </Svg>
)

export default Icon
