import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = (props) => (
  <Svg width="14" height="10" viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M0 5L7.5 9.33013L7.5 0.669872L0 5ZM14 4.25L6.75 4.25L6.75 5.75L14 5.75L14 4.25Z" fill="black" fillOpacity="0.15"/>
  </Svg>
)

export default Icon
