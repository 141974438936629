import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = (props) => (
  <Svg width="163" height="38" viewBox="0 0 163 38" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M64.3792 10.9502C67.3959 10.9502 69.2523 12.2152 70.1805 13.5952L67.6279 16.0102C66.9318 14.9752 65.8875 14.4002 64.6112 14.4002C62.2907 14.4002 60.6664 16.1252 60.6664 18.6552C60.6664 21.1852 62.2907 22.9102 64.6112 22.9102C66.0035 22.9102 67.0478 22.3352 67.6279 21.3002L70.1805 23.6002C69.2523 24.9802 67.3959 26.2452 64.3792 26.2452C59.8542 26.2452 56.6055 23.0252 56.6055 18.5402C56.6055 14.0552 59.8542 10.9502 64.3792 10.9502Z" fill="#242759"/>
      <path d="M70.877 18.54C70.877 14.4 73.7776 10.835 78.6507 10.835C83.5237 10.835 86.4244 14.285 86.4244 18.54C86.4244 22.795 83.5237 26.245 78.6507 26.245C73.7776 26.245 70.877 22.68 70.877 18.54ZM82.4795 18.54C82.4795 16.355 81.0872 14.285 78.7667 14.285C76.4462 14.285 75.0539 16.24 75.0539 18.54C75.0539 20.84 76.4462 22.795 78.7667 22.795C81.0872 22.795 82.4795 20.84 82.4795 18.54Z" fill="#242759"/>
      <path d="M88.5117 6.80977C88.5117 5.54477 89.556 4.50977 90.8322 4.50977C92.1085 4.50977 93.1527 5.54477 93.1527 6.80977C93.1527 8.07477 92.1085 9.10977 90.8322 9.10977C89.556 9.10977 88.5117 8.07477 88.5117 6.80977ZM88.8598 11.2948H92.8047V25.8998H88.8598V11.2948Z" fill="#242759"/>
      <path d="M106.033 17.0445C106.033 15.0895 104.989 14.2845 103.364 14.2845C101.856 14.2845 100.696 15.0895 99.9995 16.0095V25.7845H96.0547V11.2945H99.9995V13.2495C100.928 12.0995 102.784 11.0645 105.221 11.0645C108.469 11.0645 109.978 12.7895 109.978 15.7795V26.1295H106.033V17.0445Z" fill="#242759"/>
      <path d="M130.629 11.2942H134.574V13.2492C135.618 11.9842 137.474 10.9492 139.331 10.9492V14.6292C138.983 14.5142 138.635 14.5142 138.287 14.5142C137.01 14.5142 135.27 15.2042 134.69 16.1242V25.7842H130.745L130.629 11.2942Z" fill="#242759"/>
      <path d="M159.289 16.5845C159.289 15.2045 158.709 14.2845 157.085 14.2845C155.692 14.2845 154.648 15.2045 154.068 16.0095V25.7845H150.123V16.5845C150.123 15.2045 149.543 14.2845 147.919 14.2845C146.526 14.2845 145.482 15.2045 144.902 16.0095V25.7845H140.957V11.2945H144.902V13.2495C145.482 12.3295 147.454 11.0645 149.659 11.0645C151.863 11.0645 153.14 11.9845 153.72 13.7095C154.532 12.3295 156.504 11.0645 158.709 11.0645C161.378 11.0645 163.002 12.4445 163.002 15.4345V25.8995H159.057L159.289 16.5845Z" fill="#242759"/>
      <path d="M119.839 7.95972C120.303 7.95972 120.767 8.07473 121.115 8.30473L121.695 5.42973C120.999 5.08473 120.071 4.96973 119.142 4.96973C116.242 4.96973 114.153 6.92473 114.153 10.0297V11.1797H111.717V14.5147H114.153V25.6697H118.098V14.5147H121.115V11.1797H118.098V10.0297C117.982 8.76472 118.794 7.95972 119.839 7.95972Z" fill="#242759"/>
      <path d="M122.973 6.80977C122.973 5.54477 124.017 4.50977 125.293 4.50977C126.569 4.50977 127.614 5.54477 127.614 6.80977C127.614 8.07477 126.569 9.10977 125.293 9.10977C124.133 9.10977 122.973 8.07477 122.973 6.80977ZM123.437 11.2948H127.382V25.8998H123.437V11.2948Z" fill="#242759"/>
      <path d="M33.8081 7.85277L29.084 12.5352L33.8081 17.2175L38.5323 12.5352L33.8081 7.85277Z" fill="#242759"/>
      <path d="M33.7827 20.8352L29.0586 25.5176L33.7827 30.2L38.5069 25.5176L33.7827 20.8352Z" fill="#242759"/>
      <path d="M40.3081 14.345L35.584 19.0273L40.3081 23.7097L45.0323 19.0273L40.3081 14.345Z" fill="#242759"/>
      <path d="M25.2433 16.2574L18.7478 22.6954L14.9434 19.0165L16.892 17.1771L18.7478 19.0165L23.3874 14.418L25.2433 16.2574Z" fill="#242759"/>
      <path d="M29.0482 29.2258C23.3879 34.836 14.1087 34.836 8.44841 29.2258C2.7881 23.6155 2.7881 14.4183 8.44841 8.80807C14.1087 3.19781 23.3879 3.19781 29.0482 8.80807L31.8319 6.04893C24.5942 -1.21683 12.9024 -1.21683 5.66465 6.04893C-1.57311 13.3147 -1.57311 24.9031 5.66465 32.0769C12.9024 39.2507 24.687 39.2507 31.9247 32.0769L29.0482 29.2258Z" fill="#242759"/>
  </Svg>
)

export default Icon
